import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: () => ({
    importIncomes: {},
    importIncomesRows: [],
    adsenseIncomes: [],
  }),
  getters: {
    getImportIncomes: state => state.importIncomes,
    getImportIncomesRows: state => state.importIncomesRows,
    getAdsenseIncomes: state => state.adsenseIncomes,
  },
  mutations: {
    ADD_IMPORT_INCOMES(state, data) {
      state.importIncomes = data
    },
    ADD_IMPORT_INCOMES_ROWS(state, data) {
      state.importIncomesRows = data
    },
    ADD_IMPORT_ADSENSE_INCOMES(state, data) {
      state.adsenseIncomes = data
    },
  },
  actions: {
    async fetchIncomes({ dispatch, commit, getters }, formData) {
      const {
        getImportIncomes,
      } = getters

      if (Object.keys(getImportIncomes).length === 0) {
        await useJwt.uploadAdsenseIncomes(formData)
          .then(response => {
            commit('ADD_IMPORT_INCOMES', response.data)
            commit('ADD_IMPORT_INCOMES_ROWS', response.data.rows)
          })
          .catch(err => {
            dispatch(
              'error-store/showNotify',
              {
                msg: err.message,
                title: 'Error',
                variant: 'danger',
              },
              { root: true },
            )
          })
      }
    },
    setAdsenseIncomes({ commit }, dataArray) {
      commit('ADD_IMPORT_ADSENSE_INCOMES', dataArray)
    },
  },
}
