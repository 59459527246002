export default [
  {
    path: '/system',
    name: 'settings-system',
    component: () => import('@/views/dashboard/settings/System.vue'),
    meta: {
      resource: 'ACL',
      action: 'manage',
    },
  },
]
