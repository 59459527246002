import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  namespaced: true,
  state: {
    campaignNames: JSON.parse(localStorage.getItem('campaignNames')) || [],
  },
  getters: {
    campaigns: state => state.campaignNames,
    campaignNameByIdFromStore: state => campaignId => {
      const campaign = state.campaignNames.find(s => s.id === campaignId)
      return campaign ? campaign.name : campaignId
    },
    campaignUserIdByIdFromStore: state => campaignId => {
      const campaign = state.campaignNames.find(s => s.id === campaignId)
      return campaign ? campaign.user_id : null
    },
  },
  mutations: {
    ADD_CAMPAIGNS_NAME(state, newCampaigns) {
      const currentIds = new Set(state.campaignNames.map(campaign => campaign.id))

      newCampaigns.forEach(newCampaign => {
        if (!currentIds.has(newCampaign.id)) {
          state.campaignNames.push(newCampaign)
        }
      })

      localStorage.setItem('campaignNames', JSON.stringify(state.campaignNames))
    },
  },
  actions: {
    async fetchCampaignNamesById({ commit, state }, ids) {
      const missingIds = ids.filter(id => !state.campaignNames.some(newCampaign => newCampaign.id === id))

      if (missingIds.length === 0) {
        return
      }

      try {
        const response = await useJwt.campaignNamesById(ids)
        commit('ADD_CAMPAIGNS_NAME', response.data)
      } catch (error) {
        if (error.response && error.response.status === 422 && error.response.data.errors.length) {
          error.response.data.errors.forEach(err => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: err.detail,
              },
            })
          })
        }
      }
    },
  },
}
