export default [
  {
    path: '/partners',
    name: 'dashboard-partners',
    component: () => import('@/views/dashboard/partners/Partners.vue'),
  },
  {
    path: '/partners/:id/edit',
    name: 'dashboard-partner-edit',
    component: () => import('@/views/dashboard/partners/Edit.vue'),
    active: true,
  },
  {
    path: '/partners/create',
    name: 'dashboard-partners-create',
    component: () => import('@/views/dashboard/partners/Create.vue'),
    active: true,
  },
  {
    path: '/partners/:id',
    name: 'dashboard-partner',
    component: () => import('@/views/dashboard/partners/Partner.vue'),
    active: true,
  },
  {
    path: '/partners/:id/stats',
    name: 'dashboard-partner-stats',
    component: () => import('@/views/dashboard/partners/Stats.vue'),
    active: true,
  },
  {
    path: '/partners/:id/v2/stats',
    name: 'dashboard-partner-stats-v2',
    component: () => import('@/views/dashboard/partners/StatsV2.vue'),
    active: true,
  },
  {
    path: '/partners/:id/logs',
    name: 'dashboard-partner-logs',
    component: () => import('@/views/dashboard/partners/Logs.vue'),
    active: true,
  },
]
