<template>
  <div />
</template>

<script>
import { mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'NotificationCom',
  computed: {
    ...mapGetters('error-store', ['messagePool']),
  },
  watch: {
    messagePool: 'showNotification',
  },
  methods: {
    showNotification({ variant, msg, title }) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text: msg,
          icon: 'AlertTriangleIcon',
          variant,
        },
      })
    },
  },
}
</script>
