export default [
  {
    path: '/adsense-incomes',
    name: 'adsense-incomes',
    component: () => import('@/views/dashboard/adsense-incomes/List.vue'),
    meta: {
      resource: 'ACL',
      action: 'manage',
    },
  },
  {
    path: '/adsense-incomes/:id',
    name: 'adsense-incomes-details',
    component: () => import('@/views/dashboard/adsense-incomes/AdsenseIncomesDetails.vue'),
    meta: {
      resource: 'ACL',
      action: 'manage',
    },
  },
]
