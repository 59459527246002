import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: () => ({
    accounts: [],
  }),
  getters: {
    accounts: state => state.accounts,
    account: state => id => state.accounts.find(a => a._id === id),
  },
  mutations: {
    ADD_ACCOUNT(state, data) {
      state.accounts.push(data)
    },
    CLEAR_ACCOUNTS(state) {
      state.accounts = []
    },
  },
  actions: {
    async getAccount({ getters, dispatch }, userId) {
      const storeAccounts = getters.accounts
      const idx = storeAccounts.findIndex(sa => sa._id === userId)
      if (idx !== -1) {
        return storeAccounts[idx]
      }
      return dispatch('fetchAccount', userId)
    },
    async fetchAccount({ commit, dispatch, getters }, userId) {
      const responseData = await useJwt.getUser(userId)
      const { account } = responseData.data
      const pixels = await dispatch('fethPixels', userId)
      const agencies = await dispatch('fethAgencies', userId)
      if (account.owns !== '') {
        account.owns.pixels = pixels.length
        account.owns.agencies = agencies.length
      } else {
        const newOwns = {}
        newOwns.pixels = pixels.length
        newOwns.agencies = agencies.length
        account.owns = newOwns
      }
      commit('ADD_ACCOUNT', account)
      return getters.account(userId)
    },
    async fethPixels(_, userId) {
      const responseData = await useJwt.accountPixels(userId)
      return responseData.data.pixels
    },
    async fethAgencies(_, userId) {
      const responseData = await useJwt.accountAgencies(userId)
      return responseData.data.agencies
    },
  },
}
