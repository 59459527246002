export default [
  {
    path: '/agencies',
    name: 'dashboard-agencies',
    component: () => import('@/views/dashboard/agencies/Agencies.vue'),
  },
  {
    path: '/users/:userId/agencies',
    name: 'dashboard-user-agencies',
    component: () => import('@/views/dashboard/agencies/Agencies.vue'),
  },
  {
    path: '/users/:userId/agencies/create',
    name: 'dashboard-user-agency-create',
    component: () => import('@/views/dashboard/agencies/FormNewAgency.vue'),
  },
  {
    path: '/users/:userId/agencies/:id/edit',
    name: 'dashboard-agency-edit',
    component: () => import('@/views/dashboard/agencies/Edit.vue'),
    active: true,
  },
  {
    path: '/users/:userId/agencies/:id/:memberId/edit',
    name: 'dashboard-agency-user-edit',
    component: () => import('@/views/dashboard/agencies/AgencyUserForm.vue'),
    active: true,
  },
  {
    path: '/users/:userId/agencies/:id/create',
    name: 'dashboard-agency-new-user',
    component: () => import('@/views/dashboard/agencies/AgencyNewUserForm.vue'),
    active: true,
  },
  {
    path: '/users/:userId/agencies/:id',
    name: 'dashboard-agency',
    component: () => import('@/views/dashboard/agencies/Agency.vue'),
    active: true,
  },
]
