export default [
  {
    path: '/campaigns',
    name: 'dashboard-campaigns',
    component: () => import('@/views/dashboard/campaigns/Campaigns.vue'),
    meta: {
      resource: 'ACL',
      action: 'manage',
    },
  },
  {
    path: '/users/:userId/campaigns/:id/stats-sites',
    name: 'dashboard-campaigns-stats-sites',
    component: () => import('@/views/dashboard/campaigns/Statistics2.vue'),
  },
  {
    path: '/users/:userId/campaigns',
    name: 'dashboard-user-campaigns',
    component: () => import('@/views/dashboard/campaigns/Campaigns.vue'),
  },
  {
    path: '/users/:userId/campaigns',
    name: 'dashboard-user-toolbar-campaigns',
    component: () => import('@/views/dashboard/campaigns/Campaigns.vue'),
  },
  {
    path: '/users/:userId/campaigns/create',
    name: 'dashboard-user-campaigns-create',
    component: () => import('@/views/dashboard/campaigns/FormNewCampaign.vue'),
  },
  // {
  //   path: '/users/:userId/campaigns/:id',
  //   name: 'dashboard-user-campaign',
  //   component: () => import('@/views/dashboard/campaigns/Campaign.vue'),
  // },
  {
    path: '/users/:userId/campaigns/:id',
    name: 'dashboard-user-campaign',
    component: () => import('@/views/dashboard/campaigns/Campaign.vue'),
  },
  {
    path: '/users/:userId/campaigns/v2/:id',
    name: 'dashboard-user-campaign-v2',
    component: () => import('@/views/dashboard/campaigns/CampaignV2.vue'),
  },
  {
    path: '/users/:userId/campaigns/:id/stats',
    name: 'dashboard-campaigns-stats',
    component: () => import('@/views/dashboard/campaigns/Stats.vue'),
  },
  {
    path: '/users/:userId/campaigns/:id/edit',
    name: 'dashboard-campaigns-edit',
    component: () => import('@/views/dashboard/campaigns/Edit.vue'),
  },
  {
    path: '/users/:userId/campaigns/:id/payments',
    name: 'dashboard-campaigns-payments',
    component: () => import('@/views/dashboard/campaigns/CampaignPayments.vue'),
  },
  {
    path: '/users/:userId/campaigns/:id/clicks',
    name: 'dashboard-campaign-clicksources',
    component: () => import('@/views/dashboard/campaigns/Clicks.vue'),
  },
  {
    path: '/users/:userId/campaigns/:id/pixelclicks',
    name: 'dashboard-campaign-pixelclicks',
    component: () => import('@/views/dashboard/campaigns/PixelClicks.vue'),
  },
  {
    path: '/users/:userId/campaigns/:id/impressions',
    name: 'dashboard-campaign-impressions',
    component: () => import('@/views/dashboard/campaigns/Impressions.vue'),
  },
  {
    path: '/users/:userId/campaigns/:id/geoclicks',
    name: 'dashboard-campaign-geoclicks',
    component: () => import('@/views/dashboard/campaigns/GeoClicks.vue'),
  },
  {
    path: '/users/:userId/campaigns/:id/creatives',
    name: 'dashboard-campaigns-creatives',
    component: () => import('@/views/dashboard/creatives/Creatives.vue'),
  },
  {
    path: '/users/:userId/campaigns/:id/creatives/table',
    name: 'dashboard-campaigns-creatives-table',
    component: () => import('@/views/dashboard/creatives/TableCreatives.vue'),
  },
  {
    path: '/users/:userId/campaigns/:id/creatives/:bannerId/edit',
    name: 'dashboard-campaigns-creative-edit',
    component: () => import('@/views/dashboard/creatives/FormCreative.vue'),
  },
  {
    path: '/users/:userId/campaigns/:id/creatives/:bannerId/create',
    name: 'dashboard-campaigns-creative-create',
    component: () => import('@/views/dashboard/creatives/FormNewCreative.vue'),
  },
  {
    path: '/users/:userId/campaigns/:id/creatives/:bannerId/stats',
    name: 'dashboard-campaigns-creative-stats',
    component: () => import('@/views/dashboard/creatives/Stats.vue'),
  },
  {
    path: '/users/:userId/campaigns/:id/criteo',
    name: 'dashboard-campaigns-criteo-stats',
    component: () => import('@/views/dashboard/campaigns/CriteoStats.vue'),
  },
]
