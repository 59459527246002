import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
    campaigns: [],
  },
  getters: {
    campaigns: state => state.campaigns,
  },
  mutations: {
    ADD_CAMPAIGNS(state, data) {
      state.campaigns = data
    },
  },
  actions: {
    async fetchCampaigns({ commit, getters }) {
      const response = await useJwt.campaigns()
      commit('ADD_CAMPAIGNS', response.data.campaigns)
      return getters.campaigns
    },
  },
}
