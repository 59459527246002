export default [
  {
    path: '/notifications',
    name: 'dashboard-notifications',
    component: () => import('@/views/dashboard/notifications/Notifications.vue'),
    meta: {
      resource: 'ACL',
      action: 'manage',
    },
  },
]
