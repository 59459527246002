export default [
  {
    path: '/users',
    name: 'dashboard-users',
    component: () => import('@/views/dashboard/users/Users.vue'),
    meta: {
      resource: 'ACL',
      action: 'manage',
    },
  },
  {
    path: '/users/:userId/edit',
    name: 'dashboard-users-edit',
    component: () => import('@/views/dashboard/users/Edit.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/users/:userId',
    name: 'dashboard-users-page',
    component: () => import('@/views/dashboard/users/User.vue'),
  },
  {
    path: '/users/:userId/payments',
    name: 'dashboard-users-payments',
    component: () => import('@/views/dashboard/users/UserPayments.vue'),
  },
  {
    path: '/users/:userId/pay',
    name: 'dashboard-users-payments-methods',
    component: () => import('@/views/dashboard/users/UserPaymentsMethods.vue'),
    meta: {
      resource: 'ACL',
      action: 'manage',
    },
    beforeEnter: (to, from, next) => {
      const isRouteTemporarilyClosed = true
      if (isRouteTemporarilyClosed) {
        next(`/users/${to.params.userId}`)
      } else {
        next()
      }
    },
  },
  {
    path: '/users/:userId/transaction',
    name: 'dashboard-users-transaction',
    component: () => import('@/views/dashboard/users/UserTransaction.vue'),
  },
]
