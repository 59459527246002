export default [
  {
    path: '/decode-url',
    name: 'decode-url',
    component: () => import('@/views/dashboard/utils/DecodeUrl.vue'),
    meta: {
      resource: 'ACL',
      action: 'manage',
    },
  },
]
