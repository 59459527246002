export default [
  {
    path: '/dsppartners',
    name: 'dashboard-dsppartners',
    component: () => import('@/views/dashboard/dsppartners/DspPartners.vue'),
  },
  {
    path: '/dsppartners/:id/edit',
    name: 'dashboard-dsppartner-edit',
    component: () => import('@/views/dashboard/dsppartners/Edit.vue'),
    active: true,
  },
  {
    path: '/dsppartners/create',
    name: 'dashboard-dsppartners-create',
    component: () => import('@/views/dashboard/dsppartners/Create.vue'),
    active: true,
  },
  {
    path: '/dsppartners/:id',
    name: 'dashboard-dsppartner',
    component: () => import('@/views/dashboard/dsppartners/DspPartner.vue'),
    active: true,
  },
  {
    path: '/dsppartners/:id/stats',
    name: 'dashboard-dsppartner-stats',
    component: () => import('@/views/dashboard/dsppartners/Stats.vue'),
    active: true,
  },
  {
    path: '/dsppartners/:id/logs',
    name: 'dashboard-dsppartner-logs',
    component: () => import('@/views/dashboard/dsppartners/Logs.vue'),
    active: true,
  },
]
