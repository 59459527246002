import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  namespaced: true,
  state: {
    siteNames: JSON.parse(localStorage.getItem('siteNames')) || [],
  },
  getters: {
    sites: state => state.siteNames,
    siteNameByIdFromStore: state => siteId => {
      const site = state.siteNames.find(s => s.id === siteId)
      return site ? site.name : siteId
    },
  },
  mutations: {
    ADD_SITES_NAME(state, newSites) {
      const currentIds = new Set(state.siteNames.map(site => site.id))

      newSites.forEach(newSite => {
        if (!currentIds.has(newSite.id)) {
          state.siteNames.push(newSite)
        }
      })

      localStorage.setItem('siteNames', JSON.stringify(state.siteNames))
    },
  },
  actions: {
    async fetchSiteNamesById({ commit, state }, ids) {
      const missingIds = ids.filter(id => !state.siteNames.some(site => site.id === id))

      if (missingIds.length === 0) {
        return
      }

      try {
        const response = await useJwt.siteNamesById(ids)
        commit('ADD_SITES_NAME', response.data)
      } catch (error) {
        if (error.response && error.response.status === 422 && error.response.data.errors.length) {
          error.response.data.errors.forEach(err => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: err.detail,
              },
            })
          })
        }
      }
    },
  },
}
