import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  namespaced: true,
  state: {
    partnerNames: JSON.parse(localStorage.getItem('partnerNames')) || [],
  },
  getters: {
    partners: state => state.partnerNames,
    partnerNameByIdFromStore: state => partnerId => {
      const partner = state.partnerNames.find(p => p.id === partnerId)
      return partner ? partner.name : partnerId
    },
  },
  mutations: {
    ADD_PARTNER_NAMES(state, newPartners) {
      const currentIds = new Set(state.partnerNames.map(p => p.id))

      newPartners.forEach(newPartner => {
        if (!currentIds.has(newPartner.id)) {
          state.partnerNames.push(newPartner)
        }
      })

      localStorage.setItem('partnerNames', JSON.stringify(state.partnerNames))
    },
  },
  actions: {
    async fetchPartnerNamesById({ commit, state }, ids) {
      const missingIds = ids.filter(id => !state.partnerNames.some(p => p.id === id))

      if (missingIds.length === 0) {
        return
      }

      try {
        const response = await useJwt.partnerNamesById(ids)
        commit('ADD_PARTNER_NAMES', response.data)
      } catch (error) {
        if (error.response && error.response.status === 422 && error.response.data.errors.length) {
          error.response.data.errors.forEach(err => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: err.detail,
              },
            })
          })
        }
      }
    },
  },
}
