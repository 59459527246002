import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import campaigns from './routes/campaigns'
import users from './routes/users'
import sites from './routes/sites'
import pixels from './routes/pixels'
import agencies from './routes/agencies'
import partners from './routes/partners'
import dsppartners from './routes/dsppartners'
import prebidpartners from './routes/prebidpartners'
import currencies from './routes/currencies'
import reports from './routes/reports'
import pages from './routes/pages'
import notifications from './routes/notifications'
import adsenseIncomes from './routes/adsense-incomes'
import utils from './routes/utils'
import settings from './routes/settings'

Vue.use(VueRouter)
const userData = getUserData() || null
const redirectParams = userData ? { name: 'dashboard-users-page', params: { userId: userData.user_id } } : { name: 'auth-login' }

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, _, savedPosition) {
    if (savedPosition) return savedPosition
    if (to.params.savePosition) return {}

    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: redirectParams },
    ...campaigns,
    ...users,
    ...sites,
    ...pixels,
    ...agencies,
    ...partners,
    ...dsppartners,
    ...prebidpartners,
    ...currencies,
    ...reports,
    ...pages,
    ...notifications,
    ...adsenseIncomes,
    ...utils,
    ...settings,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login', query: { redirect: to.fullPath } })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next(getHomeRouteForLoggedInUser(userData))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
