export default [
  {
    path: '/prebidpartners',
    name: 'dashboard-prebidpartners',
    component: () => import('@/views/dashboard/prebidpartners/PrebidPartners.vue'),
  },
  {
    path: '/prebidpartners/create',
    name: 'dashboard-prebidpartners-create',
    component: () => import('@/views/dashboard/prebidpartners/Create.vue'),
    active: true,
  },
  {
    path: '/prebidpartners/:id',
    name: 'dashboard-prebidpartner',
    component: () => import('@/views/dashboard/prebidpartners/PrebidPartner.vue'),
    active: true,
  },
  {
    path: '/prebidpartners/:id/edit',
    name: 'dashboard-prebidpartner-edit',
    component: () => import('@/views/dashboard/prebidpartners/Edit.vue'),
    active: true,
  },
  {
    path: '/prebidpartners/:id/stats',
    name: 'dashboard-prebidpartner-stats',
    component: () => import('@/views/dashboard/prebidpartners/Stats.vue'),
    active: true,
  },
  {
    path: '/prebidpartners/:id/v2/stats',
    name: 'dashboard-prebidpartner-stats-v2',
    component: () => import('@/views/dashboard/prebidpartners/StatsV2.vue'),
    active: true,
  },
]
