export default [
  {
    path: '/pixels',
    name: 'dashboard-pixels',
    component: () => import('@/views/dashboard/pixels/Pixels.vue'),
  },
  {
    path: '/users/:userId/pixels',
    name: 'dashboard-user-pixels',
    component: () => import('@/views/dashboard/pixels/Pixels.vue'),
  },
  {
    path: '/users/:userId/pixels/create',
    name: 'dashboard-user-pixels-create',
    component: () => import('@/views/dashboard/pixels/FormNewPixel.vue'),
  },
  {
    path: '/users/:userId/pixels/:id/edit',
    name: 'dashboard-pixels-edit',
    component: () => import('@/views/dashboard/pixels/Edit.vue'),
  },
  {
    path: '/users/:userId/pixels/:id',
    name: 'dashboard-pixel',
    component: () => import('@/views/dashboard/pixels/Pixel.vue'),
  },
]
