import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import userStoreModule from '@/views/dashboard/users/userStoreModule'
import userToolbarStoreModule from '@/views/dashboard/users/userToolbarStoreModule'
import campaingStoreModule from '@/views/dashboard/campaigns/campaingStoreModule'
import incomesStoreModule from '@/views/dashboard/adsense-incomes/incomesStoreModule'
import errorStoreModule from '@/views/error/errorStoreModule'
import siteStatsStoreModule from '@/views/dashboard/sites/siteStatsStoreModule'
import partnerStatsStoreModule from '@/views/dashboard/partners/partnersStatsStoreModule'
import campaignStatsStoreModule from '@/views/dashboard/campaigns/campaignStatsStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'dashboard-user': userStoreModule,
    'dashboard-user-toolbar': userToolbarStoreModule,
    'dashboard-campaings': campaingStoreModule,
    'dashboard-campaings-stats': campaignStatsStoreModule,
    'dashboard-incomes': incomesStoreModule,
    'error-store': errorStoreModule,
    'dashboard-sites-stats': siteStatsStoreModule,
    'dashboard-partners-stats': partnerStatsStoreModule,
  },
  strict: process.env.DEV,
})
