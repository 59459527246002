import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: () => ({
    user: null,
    users: [],
    currentPage: 1,
    perPage: 20,
  }),
  getters: {
    user: state => state.user,
    users: state => state.users,
    getCurrentPage: state => state.currentPage,
    getPerPage: state => state.perPage,
    pageUsers: state => (from, to) => state.users.slice(from, to),
    totalUsers: state => state.users.length,
  },
  mutations: {
    ADD_USER(state, data) {
      state.user = data
    },
    REMOVE_USER(state) {
      state.user = null
    },
    ADD_USERS(state, data) {
      state.users = data
    },
    CURRENT_PAGE(state, value) {
      state.currentPage = value
    },
  },
  actions: {
    async setUser({ commit, getters }, userId) {
      const responseData = await useJwt.getUser(userId)
      const { account } = responseData.data
      commit('ADD_USER', account)
      return getters.user
    },
    async fetchUsers({ commit, getters }) {
      const {
        users, getCurrentPage, getPerPage, pageUsers,
      } = getters
      const from = getCurrentPage * getPerPage - getPerPage
      const to = getCurrentPage * getPerPage

      if (users.length === 0) {
        const response = await useJwt.users()
        commit('ADD_USERS', response.data.accounts)
        return pageUsers(from, to)
      }

      return pageUsers(from, to)
    },
    changeCurrentPage({ commit, dispatch }, page) {
      commit('CURRENT_PAGE', page)
      dispatch('fetchUsers')
    },
  },
}
