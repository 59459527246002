export default {
  namespaced: true,
  state: () => ({
    messagePool: [],
  }),
  getters: {
    messagePool: ({ messagePool }) => messagePool[messagePool.length - 1],
  },
  mutations: {
    showToastNotify(state, msg) {
      state.messagePool.push(msg)
    },
    clearMessagePool(state) {
      state.messagePool = []
    },
  },
  actions: {
    showNotify({ commit }, msg) {
      commit('clearMessagePool')
      commit('showToastNotify', msg)
    },
  },
}
