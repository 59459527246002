import { $themeConfig } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    isVerticalMenuCollapsed: $themeConfig.layout.menu.isCollapsed,
    accountId: '',
  },
  getters: {
    accountId: state => state.accountId,
  },
  mutations: {
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      state.isVerticalMenuCollapsed = val
    },
    ADD_ACCOUNT_ID(state, userId) {
      state.accountId = userId
    },
    CLEAR_ACCOUNT_ID(state) {
      state.accountId = ''
    },
  },
  actions: {
    setAccountId({ commit }, userId) {
      commit('ADD_ACCOUNT_ID', userId)
    },
    removeAccountId({ commit }) {
      commit('CLEAR_ACCOUNT_ID')
    },
  },
}
