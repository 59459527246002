export default [
  {
    path: '/sites',
    name: 'dashboard-sites',
    component: () => import('@/views/dashboard/sites/Sites.vue'),
    meta: {
      resource: 'ACL',
      action: 'manage',
    },
  },
  {
    path: '/users/:userId/sites',
    name: 'dashboard-user-sites',
    component: () => import('@/views/dashboard/sites/SitesAccount.vue'),
  },
  {
    path: '/users/:userId/sites',
    name: 'dashboard-user-toolbar-sites',
    component: () => import('@/views/dashboard/sites/SitesAccount.vue'),
  },
  {
    path: '/users/:userId/sites/create',
    name: 'dashboard-user-site-create',
    component: () => import('@/views/dashboard/sites/FormNewSite.vue'),
  },
  {
    path: '/users/:userId/sites/:siteId',
    name: 'dashboard-site',
    component: () => import('@/views/dashboard/sites/Site.vue'),
  },
  {
    path: '/users/:userId/sites/v2/:siteId',
    name: 'dashboard-site-v2',
    component: () => import('@/views/dashboard/sites/SiteV2.vue'),
  },
  {
    path: '/users/:userId/sites/:siteId/edit',
    name: 'dashboard-sites-edit',
    component: () => import('@/views/dashboard/sites/Edit.vue'),
  },
  // {
  //   path: '/users/:userId/sites/:siteId/stats',
  //   name: 'dashboard-site-stats',
  //   component: () => import('@/views/dashboard/sites/Stats.vue'),
  // },
  {
    path: '/users/:userId/sites/:siteId/clicks',
    name: 'dashboard-site-clicks',
    component: () => import('@/views/dashboard/sites/Clicks.vue'),
  },
  {
    path: '/users/:userId/sites/:siteId/promotions',
    name: 'dashboard-site-promotions',
    component: () => import('@/views/dashboard/promotions/Promotions.vue'),
  },
  {
    path: '/users/:userId/sites/:siteId/promotions/:promotionId/edit',
    name: 'dashboard-site-promotion-edit',
    component: () => import('@/views/dashboard/promotions/FormPromotion.vue'),
  },
  {
    path: '/users/:userId/sites/:siteId/promotions/create',
    name: 'dashboard-site-promotion-create',
    component: () => import('@/views/dashboard/promotions/FormNewPromotion.vue'),
  },
  {
    path: '/users/:userId/sites/:siteId/promotions/:promotionId',
    name: 'dashboard-site-promotion-page',
    component: () => import('@/views/dashboard/promotions/PromotionPages.vue'),
  },
  {
    path: '/users/:userId/sites/:siteId/promotions/:promotionId/promotionpages/create',
    name: 'dashboard-site-promotion-page-create',
    component: () => import('@/views/dashboard/promotions/FormNewPromotionPage.vue'),
  },
  {
    path: '/users/:userId/sites/:siteId/promotions/:promotionId/promotionpages/:promotionpageId/edit',
    name: 'dashboard-site-promotion-page-edit',
    component: () => import('@/views/dashboard/promotions/FormPromotionPage.vue'),
  },
  {
    path: '/users/:userId/sites/:siteId/promotions/:promotionId/promotionpages/:promotionpageId',
    name: 'dashboard-site-promotion-page-stats',
    component: () => import('@/views/dashboard/promotions/Stats.vue'),
  },
  {
    path: '/users/:userId/sites/:siteId/toparticles',
    name: 'dashboard-site-toparticles',
    component: () => import('@/views/dashboard/sites/TopArticles.vue'),
  },
  {
    path: '/users/:userId/sites/:siteId/widgets',
    name: 'dashboard-site-widgets',
    component: () => import('@/views/dashboard/widgets/Widgets.vue'),
  },
  {
    path: '/users/:userId/sites/:siteId/widgets/:widgetId/edit',
    name: 'dashboard-site-widget-edit',
    component: () => import('@/views/dashboard/widgets/FormWidget.vue'),
  },
  {
    path: '/users/:userId/sites/:siteId/widgets/create',
    name: 'dashboard-site-widget-create',
    component: () => import('@/views/dashboard/widgets/FormNewWidget.vue'),
  },
  {
    path: '/users/:userId/sites/:siteId/widgets/:widgetId',
    name: 'dashboard-site-widget',
    component: () => import('@/views/dashboard/widgets/Widget.vue'),
  },
  {
    path: '/users/:userId/sites/:siteId/widgets/:widgetId/stats',
    name: 'dashboard-site-widget-stats',
    component: () => import('@/views/dashboard/widgets/Stats.vue'),
  },
  {
    path: '/users/:userId/sites/:siteId/widgets/:widgetId/v2/stats',
    name: 'dashboard-site-widget-stats-v2',
    component: () => import('@/views/dashboard/widgets/StatsV2.vue'),
  },
  {
    path: '/users/:userId/sites/:siteId/widgets/:widgetId/clicks',
    name: 'dashboard-site-widget-clicks',
    component: () => import('@/views/dashboard/widgets/Clicks.vue'),
  },
]
